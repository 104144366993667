import React, { useEffect, useState } from 'react'
import Name from './common/Name'
import BaseURL from './common/Baseurl';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const Certificate = () => {
	const navigate = useNavigate();
	const [name,setName] = useState();
	const [otpMatchStatus,setOtpMatchStatus] = useState(0);

	localStorage.setItem('name', JSON.stringify({"name":name}))

	const user_details = JSON.parse(localStorage.getItem('user_details'));

	// alert(user_details.role_id);

	const otp_match_status = JSON.parse(
		localStorage.getItem("otp_match_status")
	  );


	const [user, SetUserDetails] = useState([]);

	// console.warn(user.phone);

	let mobemail = '';
	if(user.phone == '' || user.phone == undefined)
	{
		 mobemail = user.email;
	}else{
		 mobemail = user.phone;
	}

	// console.log(user.certificate_download);
	const [age, SetAge] = useState();
	let gender = 'करूँगा';
	if (user.gender === 'female') {
		gender = 'करुँगी';
	}


	let rawHTML = '';
	if(user_details.role_id == '16')
	{
		 rawHTML =
		`<div style="margin-top:130px;margin-left:130px;">
			<div style="
			height: 760px;
    background-image: url(assets/img/certificate.jpeg);
    background-size: cover;
    width: 537px;
					">

				<h1 style="font-size: 28px;
				line-height: 38px;
				text-align: center;
				text-shadow: 2px 1px 0 #dcb252;
				font-family: 'Mukta';
				font-weight: bold;
				color: #4f5151;
				padding-top: 175px;
		}
						">
					मैं <br> तारों की <br> टोली का एक <br> सितारा हूँ
				</h1>
				<p style="
				font-size: 23px;
				line-height: 33px;
				color: #4f5151;
				text-align: center;
					">
					मैं सब तारों के सतह हर खेल और<br> गतिविधि में भाग लेकर एक सामान<br> व
					सुरक्षित समाज का निर्माण ${gender}

				</p>

				<p style="    text-align: center;
				color: #4f5151;
				font-size: 25px;
				margin-left: 149px
		px;"> <b>नाम:</b>&nbsp; <u>${user.name}</</u></p>



			</div>
		</div>
    `;
	}else{

		 rawHTML =
		`<div style="margin-top:130px;margin-left:130px;">
			<div style="
			height: 760px;
    background-image: url(assets/img/certificate.jpeg);
    background-size: cover;
    width: 537px;
					">

				<h1 style="font-size: 28px;
				line-height: 38px;
				text-align: center;
				text-shadow: 2px 1px 0 #dcb252;
				font-family: 'Mukta';
				font-weight: bold;
				color: #4f5151;
				padding-top: 175px;
		}
						">
					मैं <br> तारों की <br> टोली का एक <br> सितारा हूँ
				</h1>
				<p style="
				font-size: 23px;
				line-height: 33px;
				color: #4f5151;
				text-align: center;
					">
					मैं सब तारों के सतह हर खेल और<br> गतिविधि में भाग लेकर एक सामान<br> व
					सुरक्षित समाज का निर्माण ${gender}

				</p>
				<p style="    text-align: center;
				color: #4f5151;
				font-size: 25px;
				margin-left: 149px
		px;"> <b>नाम:</b>&nbsp; <u>${name}</</u></p>

			</div>
		</div>
    `;


	}





	const sendOtp = (e) => {
		// console.log(e);
		e.preventDefault();
		var data = JSON.stringify({
		  emailMob: mobemail,
		  userName: user.logUsername,
		});


		var config = {
		  method: "post",
		  url: BaseURL + "/certificateDownloadSentOtp",
		  headers: {
			"Content-Type": "application/json",
		  },
		  data: data,
		};
		axios(config)
		  .then(function (response) {
			console.warn(response);
			if (response.data.success === true) {

				var otp_match_status = JSON.stringify({

					otp_match_status:0 ,

				  });
				  localStorage.setItem(
					"otp_match_status",
					otp_match_status
				  );

			  var message_det = JSON.stringify({

				email_message_id: response.data.data.message_id,

			  });
			  localStorage.setItem(
				"certificate_down_otp",
				message_det
			  );
			  navigate("/certificate-download-otp");
			  return false;
			  // navigate("/acceptance");
			} else {


			}
			// console.log(JSON.stringify(response.data));
		  })
		  .catch(function (error) {
			console.log(error);
		  });
		// console.log(mobEmail);
		// remove html tag
	  };

	const pdfDownload = () => {


		const user_id = user_details.user_id;

    var data = JSON.stringify({
      user_id: user_id,

      download_status: "1",
    });

    var config = {
      method: "post",
      url: BaseURL + "/isDownloaded",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {


        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

		var iframe = document.createElement('iframe');
		iframe.setAttribute("style", "width:0px !important;height:0px !important");
		var printPDF = document.getElementById('printPDF');
		printPDF.appendChild(iframe);
		setTimeout(function () {
			var iframedoc = iframe.contentDocument || iframe.contentWindow.document;
			iframedoc.body.innerHTML = rawHTML;
			html2canvas(iframedoc.body).then(canvas => {
				const imgData = canvas.toDataURL('image/png');
				const pdf = new jsPDF({
					orientation: 'p', // landscape
					unit: 'pt', // points, pixels won't work properly
					format: [1600, 1200] // set needed dimensions for any element
				});
				pdf.addImage(imgData, 'PNG', 0, 0);
				pdf.save('tkt-cetificate.pdf');
				// Remove
				document.querySelectorAll('iframe').forEach(
					function (elem) {
						elem.parentNode.removeChild(elem);
					});
				iframe.setAttribute("style", "display:none;");
			});
		}, 10);
	};
	useEffect(() => {
		const data1 = JSON.stringify({
			token: user_details.token,
		});
		const config1 = {
			method: "post",
			url: BaseURL + "/get_user",
			headers: {
				"Content-Type": "application/json",
			},
			data: data1,
		};
		//   console.log(config1);
		axios(config1)
			.then(function (response) {
				if (response.data.success === true) {
					SetUserDetails(response.data.data)
					let dob1 = response.data.data.dob;
					var today = new Date();
					var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
					var age_now = today.getFullYear() - birthDate.getFullYear();
					var m = today.getMonth() - birthDate.getMonth();
					if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
						age_now--;
					}
					SetAge(age_now);
					// console.log(response.data.data);
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);


	const PdfDownloadBtn = ()=>{


		if(user_details.role_id == '15')
		{
			if(otp_match_status != null){

				if(otp_match_status.otp_match_status == 1){
				return(
					<>
							<button className="mr-40 d-flex" onClick={pdfDownload}>

												{" "}
												<img src="assets/img/karo/certificate-btn.svg" alt="" />
												<span className="download-btn">
													&nbsp; सर्टिफिकेट डाउनलोड करें
												</span>
											</button>

					</>
				)
				}else{

					return(
						<>

								<button className="mr-40 d-flex" onClick={sendOtp}>
												{" "}
												<img src="assets/img/karo/certificate-btn.svg" alt="" />
												<span className="download-btn">
													&nbsp; सर्टिफिकेट डाउनलोड करें
												</span>
											</button>

						</>
					)

				}
			}else{
				return(
					<>

							<button className="mr-40 d-flex" onClick={sendOtp}>
											{" "}
											<img src="assets/img/karo/certificate-btn.svg" alt="" />
											<span className="download-btn">
												&nbsp; सर्टिफिकेट डाउनलोड करें
											</span>
										</button>

					</>
				)
			}

			}else{
				return(
					<>
							<button className="mr-40 d-flex" onClick={pdfDownload}>

												{" "}
												<img src="assets/img/karo/certificate-btn.svg" alt="" />
												<span className="download-btn">
													&nbsp; सर्टिफिकेट डाउनलोड करें
												</span>
											</button>

					</>
				)
			}

		}



		const InputBoxName = ()=>{

			if(user_details.role_id == '15')
			{
				return (
					<>

											<div className="pset-cer naamset-certificate">
													<lavel>नाम:</lavel>
													<input
														type="text"
														name=""
														className="new-cer-input"
														required=""
														placeholder="मेरा नाम-  "
														onChange={(e) => {
															setName(e.target.value);
														  }}
														value={name}
													/>
												</div>

					</>
				)
			}else{
				return (
					<>

											<div className="pset-cer naamset-certificate">
													<lavel>नाम:</lavel>
													<input
														type="text"
														name=""
														className="new-cer-input"
														required=""
														placeholder="मेरा नाम-  "

														disabled="true"
													/>
												</div>

					</>
				)
			}

		}

	return (
		<>
			<main>
				<section className="EEECE2">
					<div className="container-fluid cf-set">
						<Name />
						<div className="row align-items-center justify-content-center mt-3">
							<div className="col-4">
								<div id="pdfview" className="new-certificate">
									<div className="content-certi">
										<h1 className="certificate-h1">
											मैं <br /> तारों की <br /> टोली का एक <br /> सितारा हूँ
										</h1>
										<p className="para-certi-new">
											मैं सब तारों के सतह हर खेल और गतिविधि में भाग लेकर एक सामान व
											सुरक्षित समाज का निर्माण करुँगी करूँगा
										</p>
										<div className="row d-flex">
											<div className="col-sm ">


											<InputBoxName/>
											</div>
										</div>
									</div>
								</div>
								<div className="gkw-img d-flex">
								<PdfDownloadBtn/>

								</div>
							</div>
							<div
								className="col-lg-4 offset-lg-1 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.1s"
							>
								<center>
									<img
										draggable="false"
										width="100%"
										src="assets/img/karo/kisse-hai-meri-pehchan.svg"
									/>
								</center>
							</div>
						</div>
					</div>
				</section>
				<div id="printPDF">
				</div>
			</main>
		</>
	)
}
export default Certificate
